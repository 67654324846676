// Overrides the default autocomplete filter function to
// search only from the beginning of the string
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';
import Swal from "sweetalert2";

import ScrollMagic from 'scrollmagic';
$(document).ready(function () {

    AOS.init({
        duration: 1200
    })

    // $('.slider-for').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     fade: true,
    //     asNavFor: '.slider-nav'
    // });
    //
    // $('.slider-nav').slick({
    //     slidesToShow: 4,
    //     asNavFor: '.slider-for',
    //     dots: true,
    //     slidesToScroll: 2,
    //     centerPadding: "10px",
    //     centerMode: false, // Désactive le centrage
    //     swipeToSlide: true,
    //     //Activer le glisser indépendamment de `slidesToScroll`
    //     focusOnSelect: true,
    // });


    $('.slider-for').on('init', function (event, slick) {
        // Supprimer la classe "hidden" une fois que Slick est prêt
        $(this).removeClass('d-none');
    }).slick({
        // Options de votre carrousel
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav'

    });

    $('.slider-nav').on('init', function (event, slick) {
        // Supprimer la classe "hidden" une fois que Slick est prêt
        $(this).removeClass('d-none');
    }).slick({
        // Options de votre carrousel
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true, // Permet un défilement infini
        dots: true,
        centerPadding: "10px",
        centerMode: false,
        swipeToSlide: true,
        focusOnSelect: true,
        asNavFor: '.slider-for'
    });




    $('.video-slide').on('click', function() {
        let videoId = 's3EF4PSiH7c';
        let videoContainer = $(this);
        let uniqueId = 'youtube-player-' + videoId;
        let videoUrl = $(this).data("video");
        console.log(videoUrl)
        // Remplace la miniature par l'iframe YouTube
        let youtubeEmbed = `
            <div class="video-container">
                <iframe id="${uniqueId}" src="${videoUrl}" 
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
                </iframe>
            </div>
        `;

        // Injecter l'iframe dans le div
        videoContainer.html(youtubeEmbed);
    });

    // Détection du changement de slide dans le carrousel
    $('.slider-nav').on('afterChange', function(event, slick, currentSlide) {

        // Réinitialiser toutes les vidéos et remettre les miniatures
        $('.video-slide').each(function() {

            let largeThumbnailUrl = $(this).data("large-thumbnail");
            resetLargeThumbnail($(this), largeThumbnailUrl);

        });


        $('.video-slide-thumbnail').each(function() {

            let thumbnailUrl = $(this).data("thumbnail");

            resetThumbnail($(this), thumbnailUrl);

        });
    });

    // Fonction pour remettre la miniature
    function resetLargeThumbnail(container, largeThumbnailUrl) {

        let thumbnailHtml = `
            <div class="video-thumbnail-large-container">
                <img src="${largeThumbnailUrl}" width="100%" alt="Vidéo YouTube" class="video-thumbnail">
                <div class="play-button">▶</div>
            </div>
        `;
        container.html(thumbnailHtml);
    }

    function resetThumbnail(container, thumbnailUrl) {
        let thumbnailHtml = `
        <div class="video-thumbnail-container">
            <img src="${thumbnailUrl}" width="100%" alt="Vidéo" class="video-thumbnail">
            <div class="play-button">▶</div>
        </div>
    `;
        container.html(thumbnailHtml);
    }


    $('.image-modal').on('click', function() {
        // Récupérer l'URL de l'image en haute résolution depuis l'attribut `data-src`
        var imageUrl = $(this).data('src');
        console.log(imageUrl)
        // Utiliser SweetAlert2 pour afficher l'image dans une modal
        Swal.fire({
            imageUrl: imageUrl,        // URL de l'image
            imageWidth: '100%',         // Largeur de l'image (100% pour qu'elle s'adapte à la modal)
            imageAlt: 'Image',          // Texte alternatif
            showCloseButton: true,      // Ajouter un bouton de fermeture
            showConfirmButton: false,   // Désactiver le bouton de confirmation
            customClass: {
                popup: 'sweetalert-image' // Classe personnalisée pour la modal (facultatif)
            }
        });
    });

    var controller = new ScrollMagic.Controller();

    if ($(".home-lst-srv .item").length > 0) {
        var scene = new ScrollMagic.Scene({
            triggerElement: 'h1',
            reverse: false
        })
            .setClassToggle('.home-lst-srv .item', 'show')
            .addTo(controller);

    }






        renderProgramsMapComponent('programs-map');
        const menuToggle = document.getElementById("menu-toggle");
        const sidebarMenu = document.getElementById("sidebar-menu");
        menuToggle.addEventListener("click", function () {

        sidebarMenu.classList.toggle("active");
    });

        // Fermer le menu si l'utilisateur clique en dehors
        document.addEventListener("click", function (event) {
        if (!sidebarMenu.contains(event.target) && !menuToggle.contains(event.target)) {
        sidebarMenu.classList.remove("active");
    }
    });






// //initialisation du module Tabs de la librairie jQuery UI
// //     $("#tabs").tabs();
//
//     //Lorsqu'une des cases du filtre de recherche est cochée, la balise input est checked
//     $(".form-check-inline").click(function () {
//
//         //Display or hide the matched elements
//         $(this).find('.fa-check').toggle();
//         //Récupère l'id
//         var id = "#" + $(this).find('input').attr('id');
//
//         if ($(id).prop("checked") == true) {
//             $(id).prop("checked", false);
//
//         }
//         else {
//             $(id).prop("checked", true)
//         }
//
//     });
//
//     //Affiche les icones selon les cases cochées
//     $(".checkbox-block .form-check-inline").each(function (index) {
//
//
//         var id = "#" + $(this).find('input').attr('id');
//
//         if ($(id).prop("checked") == true) {
//
//             $(this).find('.fa-check').show();
//         }
//
//
//     });
//
//
//     var level;
//
//     if ($("#item_filter_niveau").attr("value") != null || $("#userlot_filter_lot_niveau").attr("value") != null) {
//         if ($("#item_filter_niveau").length) {
//
//             level = parseInt($_GET("item_filter%5Bniveau%5D"));
//
//         }
//         else if ($("#userlot_filter_lot_niveau").length) {
//
//             level = parseInt($_GET("userlot_filter%5Blot%5D%5Bniveau%5D"));
//         }
//
//         jQuery("#front-slider-level-number").text(level);
//     }
//     else {
//         level = 0;
//     }
//
//     // $("#front-slider-level").slider(
//     //     {
//     //         min: -5,
//     //         max: 5,
//     //         value: level,
//     //         slide: function (event, ui) {
//     //
//     //             if ($("#niveau").length) {
//     //
//     //                 jQuery("#niveau").attr("value", ui.value);
//     //             }
//     //
//     //             else if ($("#userlot_filter_lot_niveau").length) {
//     //
//     //                 jQuery("#userlot_filter_lot_niveau").attr("value", ui.value);
//     //             }
//     //
//     //             jQuery("#front-slider-level-number").text(ui.value);
//     //         }
//     //     });
//
//     $(".save-criterion").click(function (e) {
//         e.preventDefault();
//         $("body").append("<div class='body-overlay'></div>");
//         $('#dialog-criterion').show(500);
//     });
//
//     $("#search-send").click(function () {
//
//         var checkedMail = false;
//         var checkedTel = false;
//         var frequency = 0;
//         var searchName = $("#criterion-save").val();
//         $('#dialog-criterion').hide(500);
//
//         if ($('input[id=alert-mail]').is(':checked')) {
//             checkedMail = true;
//
//         }
//         if ($('input[id=alert-tel]').is(':checked')) {
//             checkedTel = true;
//
//         }
//
//         if ($('#alert-freq').val() != 0);
//         {
//             frequency = $('#alert-freq').val();
//         }
//
//         searchSave(searchName, checkedMail, checkedTel, frequency);
//     });
//
//
//     $('#search-select').change(function () {
//         var name = $('#search-select').val();
//         searchApply(name);
//     });
//
//     function searchSave(searchName, checkedMail, checkedTel, frequency) {
//
//         $.ajax({
//                 url: Routing.generate('search_save'),
//                 method: "post",
//                 data: {
//                     name: searchName,
//                     checkedmail: checkedMail,
//                     checkedtel: checkedTel,
//                     frequency: frequency
//                 },
//                 success: function (data, textStatus, xhr) {
//                     //console.log(xhr.status);
//                     if (xhr.status == 100) {
//
//                         $('#message').text('Vous devez vous connecter pour pouvoir poser une option.');
//                         $('.information-message').css('display', 'block');
//                     }
//                     if (xhr.status == 208) {
//
//                         $('#message').text('Votre demande est en cours de traitement.');
//                         $('.information-message').css('display', 'block');
//
//                     } else if (xhr.status == 202) {
//
//
//                         $('#message').text('Votre option a été accepté.');
//                         $('.information-message').css('display', 'block');
//
//                     } else if (xhr.status == 406) {
//
//
//                         $('#message').text("L'option a été refusé. Nous vous invitions a découvrir les autres lots proposés.");
//                         $('.information-message').css('display', 'block');
//
//
//                     } else if (xhr.status == 201) {
//                         $('#message').text('Votre recherche a bien été enregistrée.');
//                         $('.information-message').css('display', 'block');
//
//                     }
//
//
//                 }
//             }
//         );
//
//     }
//
//
// //Création de la requête par le biais de la fonciton searchApply
//     function searchApply(searchName) {
//
//         $.ajax({
//                 url: Routing.generate('search_apply'),
//                 method: "post",
//                 data: {
//                     name: searchName,
//                 },
//                 success: function (data, textStatus, xhr) {
//
//                     var route = Routing.generate('lots_prospect');
//                     window.location.replace(route + '?' + data.arguments);
//                 }
//             }
//         );
//     }
//
//     // $("#sort-select").select2({
//     //     placeholder: "Tri",
//     //     allowClear: true,
//     //     width: '100%'
//     //
//     // });
//
//     //
//     // $("#alert-freq").select2({
//     //     placeholder: "Choisir la fréquence de ses alertes",
//     //     allowClear: true,
//     //     width: '100%'
//     //
//     // });
//     //
//     // $("#search-select").select2({
//     //     placeholder: "Sélectionner une recherche",
//     //     allowClear: true,
//     //     width: '100%',
//     //
//     // });
//
//
//     // $("#item_filter_etage").select2({
//     //     placeholder: "Sélectionner un étage",
//     //     allowClear: true
//     // });
//     // $("#item_filter_typeBien_id, #typeBien").select2({
//     //     placeholder: "Type de bien",
//     //     allowClear: true,
//     //     width: '100%',
//     //
//     // });
//     //
//     //
//     // $("#item_filter_programme_fiscalite,#fiscalite").select2({
//     //     placeholder: "Type d'investissement",
//     //     allowClear: true,
//     //     width: '100%'
//     // });
//     //
//     //
//     // $("#userlot_filter_lot_typeBien_id").select2({
//     //     placeholder: "Sélectionner un type de bien",
//     //     allowClear: true,
//     //     width: '100%'
//     //
//     // });
//     //
//     //
//     // $("#item_filter_programme_ville_codeRegion, #item_filter_programme_ville_region_code").select2({
//     //     placeholder: "Sélectionner une région",
//     //     allowClear: true,
//     //     width: '100%'
//     //
//     // });
//     // $("#item_filter_programme_ville_departement_code, #userlot_filter_lot_programme_ville_numeroDepartement").select2({
//     //     placeholder: "Sélectionner un département",
//     //     allowClear: true,
//     //     width: '100%'
//     //
//     // });
//
//     // $('#datetimepicker1,fos_user_registration_form_date_naissance').datetimepicker(
//     //     {
//     //         locale: 'fr',
//     //         format: 'DD/MM/YYYY'
//     //     }
//     // );
//
//     $("#fos_user_registration_form_ville_codePostal").keyup(function () {
//         if ($(this).val().length === 5) {
//             $.ajax({
//                 type: 'get',
//                 url: Routing.generate('user_ville_search', {codePostal: $(this).val()}),
//                 beforeSend: function () {
//                     $("#fos_user_registration_form_ville_nom option").remove("");
//                 },
//                 success: function (data) {
//
//                     $("#fos_user_registration_form_ville_nom").autocomplete("option", {
//                         source: data.ville,
//                         search: "",
//                         minLength: 0
//                     });
//
//                     $('#fos_user_registration_form_ville_nom').focus().autocomplete("search", "");
//                 }
//             });
//         }
//
//         else {
//             $("#fos_user_registration_form_ville_nom").val('');
//         }
//         ;
//
//     });
//
//
//     var availableTags = [];
//
//     // $("#fos_user_registration_form_ville_nom").autocomplete({
//     //     source: availableTags
//     //
//     // });
//
//
//     $("#userbundle_prospect_ville_codePostal").keyup(function () {
//
//         if ($(this).val().length === 5) {
//             $.ajax({
//                 type: 'get',
//                 url: Routing.generate('user_ville_search', {codePostal: $(this).val()}),
//                 beforeSend: function () {
//                     $("#userbundle_prospect_ville_nom option").remove("");
//                 },
//                 success: function (data) {
//
//                     $("#userbundle_prospect_ville_nom").autocomplete("option", {
//                         source: data.ville,
//                         search: "",
//                         minLength: 0
//                     });
//
//                     $('#userbundle_prospect_ville_nom').focus().autocomplete("search", "");
//                 }
//             });
//         }
//
//         else {
//             $("#userbundle_prospect_ville_nom").val('');
//         }
//         ;
//
//     });
//
//     // $("#userbundle_prospect_ville_nom").autocomplete({
//     //     source: availableTags
//     //
//     // });
//
//
// //    //console.log($_GET());
// //
// ////on verifie l'existence de valeurs dans la requête
// //
// //    //Si la valeur gauche et la valeur droite est vide, on initialise les deux valeurs
// //    if ($("#item_filter_sfcHabitable_left_number").attr("value") == null && $("#item_filter_sfcHabitable_right_number").attr("value") == null) {
// //        lowSfc = 0;
// //        maxSfc = 1300;
// //        //Si seul la valeur item_filter de gauche n'est pas vide, on initialise les deux valeurs
// //    } else if ($("#item_filter_sfcHabitable_left_number").attr("value") != null) {
// //
// //        var lowSfc = parseInt($_GET("item_filter%5BsfcHabitable%5D%5Bleft_number%5D"));
// //        var maxSfc = parseInt($_GET("item_filter%5BsfcHabitable%5D%5Bright_number%5D"));
// //        //Si seul la valeur userlot_filter de gauche n'est pas vide, on initialise les deux valeurs
// //    }
// //    else if ($("#userlot_filter_lot_sfcHabitable_left_number").attr("value") != null) {
// //
// //        var lowSfc = parseInt($_GET("userlot_filter%5Blot%5D%5BsfcHabitable%5D%5Bleft_number%5D"));
// //        var maxSfc = parseInt($_GET("userlot_filter%5Blot%5D%5BsfcHabitable%5D%5Bright_number%5D"));
// //    }
// //
// //
// //    if ($("#item_filter_pxVenteWebCumuleTtc_left_number").attr("value") == null && $("#userlot_filter_lot_pxVenteWebCumuleTtc_right_number").attr("value") == null) {
// //        lowPx = 0;
// //        maxPx = 550000;
// //    } else if ($("#item_filter_pxVenteWebCumuleTtc_left_number").attr("value") != null) {
// //
// //        var lowPx = parseInt($_GET("item_filter%5BpxVenteWebCumuleTtc%5D%5Bleft_number%5D"));
// //        var maxPx = parseInt($_GET("item_filter%5BpxVenteWebCumuleTtc%5D%5Bright_number%5D"));
// //    } else if ($("#userlot_filter_lot_pxVenteWebCumuleTtc_left_number").attr("value") != null) {
// //
// //        var lowPx = parseInt($_GET("userlot_filter%5Blot%5D%5BpxVenteWebCumuleTtc%5D%5Bleft_number%5D"));
// //        var maxPx = parseInt($_GET("userlot_filter%5Blot%5D%5BpxVenteWebCumuleTtc%5D%5Bright_number%5D"));
// //    }
// //
// //    $("#slider-range").slider({
// //
// //        range: true,
// //        min: 0,
// //        max: 1300,
// //        values: [lowSfc, maxSfc],
// //        slide: function (event, ui) {
// //            $("#sfc").val(ui.values[0] + " m² - " + ui.values[1] + " m²");
// //            jQuery("#item_filter_sfcHabitable_left_number").attr("value", ui.values[0]);
// //            jQuery("#item_filter_sfcHabitable_right_number").attr("value", ui.values[1]);
// //            low = jQuery('#item_filter_sfcHabitable_left_number').val();
// //            max = jQuery('#item_filter_sfcHabitable_right_number').val();
// //            jQuery("#userlot_filter_lot_sfcHabitable_left_number").attr("value", ui.values[0]);
// //            jQuery("#userlot_filter_lot_sfcHabitable_right_number").attr("value", ui.values[1]);
// //            low = jQuery('#userlot_filter_lot_sfcHabitable_left_number').val();
// //            max = jQuery('#userlot_filter_lot_sfcHabitable_right_number').val();
// //        }
// //    });
// //    $("#sfc").val($("#slider-range").slider("values", 0) + " m² - " + $("#slider-range").slider("values", 1) +
// //        " m²");
// //    $("#sld-rng-bdg").slider({
// //        range: true,
// //        min: 0,
// //        max: 550000,
// //        values: [lowPx, maxPx],
// //        slide: function (event, ui) {
// //            $("#amount").val(ui.values[0].toLocaleString() + " - " + ui.values[1].toLocaleString() + " TTC");
// //            jQuery("#item_filter_pxVenteWebCumuleTtc_left_number").attr("value", ui.values[0]);
// //            jQuery("#item_filter_pxVenteWebCumuleTtc_right_number").attr("value", ui.values[1]);
// //            low = jQuery('#low').val();
// //            max = jQuery('#max').val();
// //            jQuery("#userlot_filter_lot_pxVenteWebCumuleTtc_left_number").attr("value", ui.values[0]);
// //            jQuery("#userlot_filter_lot_pxVenteWebCumuleTtc_right_number").attr("value", ui.values[1]);
// //        }
// //    });
// //    $("#amount").val($("#sld-rng-bdg").slider("values", 0).toLocaleString() + " - " + $("#sld-rng-bdg").slider("values", 1).toLocaleString() +
// //        " TTC");
//
//     $(function () {
//         $(".ion-ios-person").click(function () {
//             $(".dropdown-menu").toggle();
//         });
//         // Ici, le DOM est entièrement défini
//
//     });
//
//
//     function $_GET(param) {
//         var vars = {};
//         window.location.href.replace(location.hash, '').replace(
//             /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
//             function (m, key, value) { // callback
//                 vars[key] = value !== undefined ? value : '';
//             }
//         );
//         if (param) {
//             return vars[param] ? vars[param] : null;
//         }
//         return vars;
//     }
//
//
//     $(".item-diaporama").click(function () {
//         var imgURL = $(this).attr('src');
//
//         imgURL = imgURL.replace('slidethumbnail', 'large');
//         $("#large-img").fadeOut(400, function () {
//             $("#large-img").attr('src', imgURL);
//         })
//             .fadeIn(400);
//     });
//
//
//     $(".favorite-cnt").click(function () {
//
//         $("body").append("<div class='body-overlay'></div>");
//         if ($("#username").text() == '') {
//
//             $('#fav-message').text('Vous devez vous connecter pour pouvoir ajouter ce lot dans vos favoris.');
//             $('.information-fv-message').css('display', 'block');
//
//         }
//         else {
//             $(this).css({"background-color": "#31a2e1"});
//             $(this).find('.fa-star').css({"color": "white"});
//             $(this).css({"border": "1px solid #31a2e1"});
//             //$(this).find(".featured-property-icon").css('background', 'black');
//             var $this = $(this).parent();
//             var idlot = $this.find(".idlot").val();
//
//             $.ajax({
//                     url: Routing.generate('add-favourite'),
//                     method: "post",
//                     data: {id: idlot},
//                     success: function (data, textStatus, xhr) {
//
//                         if (xhr.status == 200 || xhr.status == 201) {
//
//
//                             $('#fav-message').text("Le lot a bien été ajouté dans vos favoris");
//                             $('.information-fv-message').css('display', 'block');
//
//                         }
//                         if (xhr.status == 204) {
//
//                             $('#fav-message').text("Le lot a déjà été ajouté dans vos favoris");
//                             $('.information-fv-message').css('display', 'block');
//
//                         }
//                     }
//                 }
//             );
//         }
//
//     });
//
//
//     $(".ok, .close").click(function () {
//
//         $(".information-fv-message").hide(500);
//         $(".body-overlay").remove();
//         $('#dialog-criterion').hide(500);
//     });
//
//     var sync1 = $("#sync1");
//     var sync2 = $("#sync2");
//     var slidesPerPage = 4; //globaly define number of elements per page
//     var syncedSecondary = true;
//
//     function syncPosition(el) {
//         //if you set loop to false, you have to restore this next line
//         //var current = el.item.index;
//
//         //if you disable loop you have to comment this block
//         var count = el.item.count - 1;
//         var current = Math.round(el.item.index - (el.item.count / 2) - .5);
//
//         if (current < 0) {
//             current = count;
//         }
//         if (current > count) {
//             current = 0;
//         }
//
//         //end block
//
//         sync2
//             .find(".owl-item")
//             .removeClass("current")
//             .eq(current)
//             .addClass("current");
//         var onscreen = sync2.find('.owl-item.active').length - 1;
//         var start = sync2.find('.owl-item.active').first().index();
//         var end = sync2.find('.owl-item.active').last().index();
//
//         if (current > end) {
//             sync2.data('owl.carousel').to(current, 100, true);
//         }
//         if (current < start) {
//             sync2.data('owl.carousel').to(current - onscreen, 100, true);
//         }
//     }
//
//     // function syncPosition2(el) {
//     //     if (syncedSecondary) {
//     //         var number = el.item.index;
//     //         sync1.data('owl.carousel').to(number, 100, true);
//     //     }
//     // }
//
//     sync2.on("click", ".owl-item", function (e) {
//         e.preventDefault();
//         var number = $(this).index();
//         sync1.data('owl.carousel').to(number, 300, true);
//     });
//
//
//     if ($('#ville').length > 0) {
//         var idSelector = '#ville';
//         cityAtcmplt(idSelector,'nom');
//     }
//
//     if ($('#city-ptz').length > 0) {
//         var idSelector = '#city-ptz';
//         cityAutocomplete(idSelector);
//     }
//
//     if ($('#city').length > 0) {
//
//     var idSelector = '#city';
//     cityAutocomplete(idSelector);
//     }
//
//     if ($('#locality').length > 0) {
// // console.log("");
//         var idSelector = '#locality';
//         cityAtcmplt(idSelector,'nom');
//     }
//
//     if ($('#postal_code').length > 0) {
//
//         var idSelector = '#postal_code';
//         cityAtcmplt(idSelector,'codePostal');
//     }
//
//     //$("#more-criteria").click(function () {
//     //
//     //    $("#other-criteria").slideToggle('slow');
//     //});
//
//
//     jQuery.fn.clickToggle = function (a, b) {
//         function cb() {
//             [b, a][this._tog ^= 1].call(this);
//         }
//
//         return this.on("click", cb);
//     };
//
//
//     $("#more-criteria").clickToggle(
//         function () {
//             $("#other-criteria").slideToggle('slow');
//
//
//         }, function () {
//             $("#other-criteria").slideToggle('slow');
//
//         });
//
//     //var regions;
//     //
//     //$.ajax({
//     //    'async': false,
//     //    url: Routing.generate('get_lat'),
//     //    type: "get",
//     //    data: 'ff',
//     //    success: function (data) {
//     //
//     //        regions = data.regions;
//     //
//     //    }
//     //});
//
//     //var departements;
//     //
//     //$.ajax({
//     //    'async': false,
//     //    url: Routing.generate('get_departement'),
//     //    type: "get",
//     //    data: 'ff',
//     //    success: function (data) {
//     //
//     //        departements = data.departements;
//     //
//     //    }
//     //});
//
//
//     var i = 0;                     //  set your counter to 1
//
//     function myLoopDepartment(i) {           //  create a loop function
//         setTimeout(function () {
//
//             //  call a 3s setTimeout when the loop is called
//             //
//             var geocoder = new google.maps.Geocoder();
//             var lat;
//
//             geocoder.geocode({'address': departements[i].nom}, function (results, status) {
//
//                 if (status == google.maps.GeocoderStatus.OK) {
//
//                     var lat = results[0].geometry.location.lat();
//                     var lng = results[0].geometry.location.lng();
//
//
//                     var coordinates = {"data": {"latitude": lat, "longitude": lng, "id": departements[i].id}};
//                     $.ajax({
//                         url: Routing.generate('set_departement_coordinates'),
//                         type: "post",
//                         data: coordinates,
//                         dataType: 'json',
//                         success: function (data) {
//
//                             e.log('ok');
//                         },
//                         error: function (XMLHttpRequest, textStatus, errorThrown) {
//                             console.log('error');
//                         }
//                     });
//
//                 }
//                 else {
//                     console.log('error geocoder');
//                 }
//
//             });
//
//             //  increment the counter
//             if (i < departements.length) {            //  if the counter < 10, call the loop function
//                 myLoop(i + 1);             //  ..  again which will trigger another
//             }                        //  ..  setTimeout()
//         }, 1000)
//     }
//
//     //myLoopDepartment(0);
//
//
//     var i = 0;                     //  set your counter to 1
//
//     function myLoopRegion() {           //  create a loop function
//         setTimeout(function () {
//
//
//             var geocoder = new google.maps.Geocoder();
//             var lat;
//
//             geocoder.geocode({'address': regions[i].nom}, function (results, status) {
//
//                 if (status == google.maps.GeocoderStatus.OK) {
//
//                     var lat = results[0].geometry.location.lat();
//                     var lng = results[0].geometry.location.lng();
//                     var coordinates = {"data": {"latitude": lat, "longitude": lng, "id": regions[i].id}};
//                     $.ajax({
//                         url: Routing.generate('set_lat'),
//                         type: "post",
//                         data: coordinates,
//                         dataType: 'json',
//                         success: function (data) {
//
//                             console.log('ok');
//                         },
//                         error: function (XMLHttpRequest, textStatus, errorThrown) {
//                             console.log('error');
//                         }
//                     });
//
//                 }
//                 else {
//                     console.log('error geocoder');
//                 }
//
//             });
//
//
//             //  call a 3s setTimeout when the loop is called
//             //  your code here
//             i++;                     //  increment the counter
//             if (i < regions.length) {            //  if the counter < 10, call the loop function
//                 myLoop();             //  ..  again which will trigger another
//             }                        //  ..  setTimeout()
//         }, 1000)
//
//     }
//
//
//     function cityAutocomplete(idSelector) {
//
//         $(idSelector).autocomplete({
//             source: []
//
//         });
//         $(idSelector).keyup(function () {
//             $.ajax({
//                 type: 'get',
//                 url: Routing.generate('search_localisation', {location: $(this).val()}),
//                 beforeSend: function () {
//                     $(idSelector + " option").remove("");
//                 },
//                 success: function (data) {
//
//                     $(idSelector).autocomplete("option", {
//
//                         source: data.location,
//                         search: "",
//                         minLength: 0
//                     });
//
//
//                     $(idSelector).focus().autocomplete("search", "");
//                 }
//             });
//
//
//         });
//     }
//
//
//     function cityAtcmplt(idSelector,field) {
//
//
//
//         $(idSelector).autocomplete({
//             source: []
//
//         });
//         $(idSelector).keyup(function () {
//             $.ajax({
//                 type: 'post',
//                 url: Routing.generate('search_locality', {location: $(this).val()}),
//                 data: {
//                     field: field,
//
//                 },
//                 beforeSend: function () {
//                     $(idSelector + " option").remove("");
//                 },
//                 success: function (data) {
//
//                     $(idSelector).autocomplete("option", {
//
//                         source: function (request, response) {
//                             response($.map(data, function (value, key) {
//
//                                 return {
//                                     label: value.postal_code+' '+value.locality,
//                                     postal_code: value.postal_code,
//                                     locality: value.locality,
//
//                                 }
//                             }));
//
//                         },
//                         // Déclenché lorsque le focus est déplacé sur un élément (sans sélection). L'action par défaut consiste à remplacer la valeur du champ de texte par la valeur de l'élément sélectionné, mais uniquement si l'événement a été déclenché par une interaction au clavier.
//                         focus: function(event, ui) {
//                             $('#locality').val(ui.item.locality);
//                             $('#postal_code').val(ui.item.postal_code);
//                             return false;
//                         },
//                         // Once a value in the drop down list is selected, do the following:
//                         select: function(event, ui) {
//                             // place the person.given_name value into the textfield called 'select_origin'...
//
//                             if(idSelector=='#ville'){
//
//                                 $('#ville').val(ui.item.locality);
//
//                             }
//                             else{
//                                 $('#locality').val(ui.item.locality);
//                                 // and place the person.id into the hidden textfield called 'link_origin_id'.
//                                 $('#postal_code').val(ui.item.postal_code);
//                             }
//
//
//
//                             return false;
//                         },
//                         search: "",
//                         minLength: 0
//                     });
//
//
//                     $(idSelector).focus().autocomplete("search", "");
//                 }
//             });
//
//
//         });
//     }
//
//
//
});

